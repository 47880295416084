import styled from 'styled-components'

const Container = styled.div`
   max-width: 1312px;
   margin: 0 auto;
   padding: 0 2rem;
   height: 100%;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 0 1rem;
   }
`

export default Container
