import styled from 'styled-components'
import PropTypes from 'prop-types'

const Heading = styled.h1`
   font-size: ${({ theme, size }) => theme.fontSize[size]};
   color: ${({ theme, color }) => theme.color[color]};
`
export default Heading

Heading.propTypes = {
   size: PropTypes.string,
   color: PropTypes.string
}

Heading.defaultProps = {
   size: 'h2',
   color: 'black'
}
